<template>
    <div class="repairdetails" v-bind:class="{show : show}">
        <div class = "container" v-bind:class="{hidden : !show}">
            <h1>ID: {{orderdetails.id}}</h1>

            <h3>{{orderdetails.description}}</h3>

            <h3>Assigned Tech:</h3>
            <div>{{orderdetails.techfirstname}}</div>

            <h3>Location:</h3>
            <div>{{orderdetails.locationname}}</div>
            <div>{{orderdetails.locationaddr1}}</div>
            <div>{{orderdetails.locationaddr2}}</div>
            <div>{{orderdetails.locationaddr3}}</div>

            <h3>Device:</h3>
            <div>{{orderdetails.device}}</div>

            <h3>Model Number:</h3>
            <div>{{orderdetails.modelnumber}}</div>

            <h3>Ordered On:</h3>
            <div>{{new Date(orderdetails.datecreated).toLocaleDateString()}}</div>

            <h3>Confirmed:</h3>
            <input type="checkbox" v-model="orderdetails.confirmed" disabled>

            <div v-if="orderdetails.confirmed">
                <h3>Confirmed Date:</h3>
                <div>{{new Date(orderdetails.confirmeddate).toLocaleDateString()}}</div>
            </div>

            <h3>Estimated Cost:</h3>
            <div>${{orderdetails.estimatedcost.toFixed(2)}}</div>

            <h3>Tech Comments:</h3>
            <div class = "comments-container">
                <div v-if="loadingcomments">Loading Comments...</div>
                <div v-if="comments.length < 1 && !loadingcomments">No Comments Found</div>
                <div class = "comment" v-for="comment in comments" :key="comment.id">
                    <div>{{new Date(comment.datecreated).toLocaleString()}}</div>
                    <div>{{comment.comment}}</div>
                </div>
            </div>


            <div class = "button" @click="$emit('close')">Close</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RepairDetails',
    props:{
        show : Boolean,
        orderdetails : Object
    },
    watch: { 
        show: function(newVal) {
            this.comments = [];
            if(newVal) this.loadComments();
        }
    },
    data: function(){
        return{
            api : "https://danstechservices.us:3000",
            loadingcomments : true,
            comments : []
        }
    },
    methods:{
        loadComments(){
            fetch(this.api + "/api/getcomments", { method: "POST", headers : { orderdata : this.orderdetails.id } })
            .then( res => res.json() )
            .then( res => {
                this.comments = res.response;
                this.loadingcomments = false;
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.repairdetails{
    position: absolute;
    width: 0px;
    min-height: 100%;
    top: 100px;
    left: 0px;
    background-color: dodgerblue;
    color: white;
    transition: ease-in-out 0.5s;
    z-index: 100;
}

.show{
    width: 100%;
}

.hidden{
    display: none;
}

.button{
    margin: 30px auto 30px auto;
    border-radius: 7px;
    padding: 20px;
    background-color: rgb(105, 236, 214);
    color: #2c3e50;
    width: 20%;
    cursor: pointer;
    transition: ease-in-out 0.2s;
    font-weight: bold;
}

.button:hover{
    background-color: rgb(155, 187, 235);
}

.comment{
    background-color: rgb(105, 236, 214);
    color: #2c3e50;
    border-radius: 7px;
    margin: auto;
    width: 80%;
    margin-bottom: 10px;
    padding: 20px 0 20px 0;
}

@media only screen and (max-width: 700px) {
    .repairdetails{
        top: 200px;
    }
}
</style>