<template>
    <div class="page">
        <Header />
        <h1>Check The Status of Your Repair</h1>
        <h3>Enter your phone number (Numbers only, no dashes):</h3>

        <input type="tel" v-model="phonenumber" @keyup.enter="getOrders"/>

        <h3>Or your email address:</h3>

        <input type="email" v-model="email" @keyup.enter="getOrders"/>

        <div class = "button" @click="getOrders">Find My Repairs</div>

        <div v-if="searched">
            <h1>Active Repairs</h1>
            <h3 v-if="loadingopenorders">Loading Orders...</h3>
            <h3 v-if="openorders.length < 1 && !loadingopenorders">No Open Orders Found</h3>

            <div v-for="order in openorders" :key="order.id">
                <RepairCard :order = "order" @selected="selectedorder=order; showorderdetails=true;" />
            </div>
        </div>

        <div v-if="searched">
            <h1>Completed Repairs</h1>
            <h3 v-if="loadingclosedorders">Loading Orders...</h3>
            <h3 v-if="closedorders.length < 1 && !loadingclosedorders">No Closed Orders Found</h3>

            <div class = "repair-container closed" v-for="order in closedorders" :key="order.id">
                <RepairCard :order = "order" @selected="selectedorder=order; showorderdetails=true;" />
            </div>
        </div>
        <Footer />
        <RepairDetails :orderdetails="selectedorder" :show="showorderdetails" @close="showorderdetails = false"/>
    </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import RepairDetails from '../components/RepairDetails.vue'
import RepairCard from '../components/RepairCard.vue'


export default {
    name: 'MyRepair',
    components:{
        Header,
        Footer,
        RepairDetails,
        RepairCard
    },
    data : function(){
        return{
            api : "https://danstechservices.us:3000",
            phonenumber : "",
            email : "",
            selectedorder : {},
            searched : false,
            showorderdetails : false,
            loadingopenorders : true,
            loadingclosedorders : true,
            openorders : [],
            closedorders : []
        }
    },
    methods:{
        getParameter(search){
            var result = null, tmp = [];
            location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === search) result = decodeURIComponent(tmp[1]);
            });
            return result;
        },
        getOrders(){
            this.searched = true;
            this.openorders = [];
            this.closedorders = [];
            this.showorderdetails = false;
            this.getOpenOrders();
            this.getClosedOrders();
        },
        getOpenOrders(){
            fetch(this.api + "/api/openorders", { method: "POST", headers : { userdata : JSON.stringify({phone : this.phonenumber, email : this.email})} })
            .then( res => res.json() )
            .then( res => {
                this.loadingopenorders = false;
                this.openorders = res.response
            });
        },
        getClosedOrders(){
            fetch(this.api + "/api/closedorders", { method: "POST", headers : { userdata : JSON.stringify({phone : this.phonenumber, email : this.email})} })
            .then( res => res.json() )
            .then( res =>{
                this.loadingclosedorders = false;
                this.closedorders = res.response
            });
        }
    },
    mounted:function(){
        let number = this.getParameter('number')

        this.phonenumber = number;
        if(this.phonenumber != null && this.phonenumber != "") this.getOrders();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page{
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: #e6e6e6;
    position: absolute;
    width: 100%;
    min-height: 100%;
    left: 0;
    top: 0;
}

input{
    border-style: none;
    border: 1px solid black;
    border-radius: 7px;
    padding: 5px;
    font-size: 1.5em;
}

.button{
    margin: 30px auto 30px auto;
    border-radius: 7px;
    padding: 20px;
    background-color: rgb(105, 236, 214);
    width: 20%;
    cursor: pointer;
    transition: ease-in-out 0.2s;
    font-weight: bold;
}

.button:hover{
    background-color: rgb(155, 187, 235);
}

@media only screen and (max-width: 1180px) {
    .button {
        width: 80%;
    }
}
</style>