<template>
    <div class="repaircard" v-bind:class="{ open : order.completed == 0, closed : order.completed == 1 }">
      <div class = "id hide">
          <h3 class = "repair-data">ID: {{order.id}}</h3>
          <h3 class = "repair-data">{{order.messagecount}}</h3>
      </div>
      <div class = "repair-data-container">
          <div class = "repair-data-row">
              <div class = "repair-data">Assigned Tech</div>
              <div class = "repair-data hide">Model Number</div>
              <div class = "repair-data">Service Date</div>
              <div class = "repair-data hide">Estimated Final Cost</div>
              <div class = "repair-data hide">Comments</div>
          </div>
          <div class = "repair-data-row">
              <div class = "repair-data">{{order.techfirstname}}</div>
              <div class = "repair-data hide">{{order.modelnumber}}</div>
              <div class = "repair-data">{{adjustDate(order.confirmeddate)}}</div>
              <div class = "repair-data hide">$ {{order.estimatedcost.toFixed(2)}}</div>
              <div class = "repair-data hide">{{order.comments}}</div>
          </div>
      </div>
      <div class = "more-data" @click="$emit('selected'); scrollToTop();">&#187;</div>
    </div>
</template>

<script>
export default {
  name: 'RepairDetails',
  props:{
    order : Object
  },
  watch: { 
    show: function(newVal) {
      this.comments = [];
      if(newVal) this.loadComments();
    }
  },
  data: function(){
    return{
      api : "https://danstechservices.us:3000",
      loadingcomments : true,
      comments : [],
      selectedorder : {},
      searched : false,
      showorderdetails : false,
    }
  },
  methods:{
    loadComments(){
      fetch(this.api + "/api/getcomments", { method: "POST", headers : { orderdata : this.orderdetails.id } })
      .then( res => res.json() )
      .then( res => {
        this.comments = res.response;
        this.loadingcomments = false;
    });
    },
    adjustDate(date){
      let current = new Date(date).getTime() + 86400000;
      let updated = new Date(current).toLocaleDateString();
      return updated;
    },
    scrollToTop(){
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.repaircard{
    padding: 20px;
    width: 80%;
    height: 100px;
    margin: 10px auto 10px auto;
    display: flex;
    position: relative;
    border-radius: 7px;
}

.repaircard.open{
    background-color: rgb(105, 236, 214);
}

.repaircard.closed{
    background-color: rgb(180, 180, 180);
}

.id{
    width: 70px;
    border-right: 1px solid #2c3e50;
}

.repair-data-container{
    display: block;
    width: calc(100% - 70px);
}

.repair-data-row{
    display: flex;
    height: 50%;
    width: 80%;
    margin: auto;
    align-content: center;
    flex-wrap: nowrap;
}

.repair-data{
    width: 20%;
}

.more-data{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    font-size: 24px;
    font-weight: bold;
    line-height: 132px;
    width: 100px;
    background-color: rgb(155, 187, 235);
    border-radius: 0 7px 7px 0;
    cursor: pointer;
    transition: ease-in-out 0.2s;
}

.more-data:hover{
  background-color: rgb(190, 206, 229);
  translate: 5px;
}

@media only screen and (max-width: 1150px) {
  .more-data{
    width: 50px;
  }

  .hide{
    display: none;
  }

  .repair-data-row{
    width: 100%;
  }

  .repair-data{
    width: 50%;
  }
}
</style>